<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="(item, i) in items" :key="i" cols="3">
        <v-card :color="item.color" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">{{ item.title }}</v-card-title>

              <v-card-subtitle>{{ item.description }}</v-card-subtitle>

              <v-card-actions class="pb-5">
                <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                  @click="navigateSetting(item.route)"
                >
                  Go To {{ item.title }} Setting
                </v-btn>
              </v-card-actions>
            </div>

            <div class="pa-5">
              <v-icon x-large> {{ item.mdiIcon }} </v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import beforeEnterRoleCheck from "@/services/hrdc/objects/beforeEnterRoleCheck";

export default {
  mixins: [beforeEnterRoleCheck],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  data: () => ({
    items: [
      {
        color: "#1F7087",
        title: "Price",
        description: "Configure pricing related details",
        route: "HrdcSettingsPrice",
        mdiIcon: "mdi-tag-outline",
      },
      {
        color: "red lighten-2",
        title: "Error Log",
        description: "Check for the HRDC Error Logs",
        route: "HrdcSettingsErrorLog",
        mdiIcon: "mdi-book-open-outline",
      },
      {
        color: "primary lighten-2",
        title: "Job",
        description: "Check for the Cron Job Execution",
        route: "HrdcSettingsJob",
        mdiIcon: "mdi-robot",
      },
      {
        color: "blue-grey lighten-2",
        title: "XERO Token",
        description: "Generate new XERO access token",
        route: "HrdcSettingsXeroToken",
        mdiIcon: "mdi-shield-check",
      },
    ],
  }),
  async created() {
    // Perform first check on user role, before enter (to prevent salesperson enter to unwanted view).
    this.checkRoles(this.auth.roles);
  },
  methods: {
    navigateSetting(route) {
      this.$router.push({
        name: route,
      });
    },
  },
};
</script>
